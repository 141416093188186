import React, {useEffect} from 'react'
import {
    BlankSlate,
    Description,
    PrimaryActionButton,
    SecondaryActionButton,
    SubTitle
} from "@cjdev/visual-stack/lib/components/BlankSlate";
import "@cjdev/visual-stack/lib/css/base.css"
import "@cjdev/visual-stack/lib/css/bootstrap-cj.css"
import "@cjdev/visual-stack/lib/css/forms.css"
import "@cjdev/visual-stack/lib/css/layout.css"
import "@cjdev/visual-stack/lib/css/globalVariables.css"

import "./ErrorMessage.css"
import loadingSvg from "./blankslate-loading-icon.svg"
import infoSvg from "./blankslate-info-icon.svg"

export const ErrorMessage = (props) => {
    useEffect(() => {
        document.title = "Error - CJ Affiliate"
    })

    let title, subtitle1, subtitle2

    const params = new URLSearchParams(window.location.search)

    // all possible parameters Auth0 might send us: https://auth0.com/docs/universal-login/custom-error-pages
    const errorCode = params.get("error")
    const language = params.get("lang")
    const clientId = params.get("client_id")
    const errorDescription = params.get("error_description")
    const trackingId = params.get("tracking_id")
    const logUrl = params.get("log_url")

    if (errorCode === "503") {
        title = "The page couldn't be displayed because the internet connection was interrupted."
        subtitle1 = "503 ERROR"
        subtitle2 = "Check your Wi-Fi or local network status and refresh the page."
    } else if (errorCode === "404") {
        title = "The page you're looking for could not be found."
        subtitle1 = "404 ERROR"
        subtitle2 = "Check the address and try again. Or navigate to the page from the CJ homepage."
    } else if (errorCode === "500") {
        title = "There's a problem loading the page."
        subtitle1 = "500 ERROR"
        subtitle2 = "There's a technical problem that has prevented the page from loading. Try reloading the page or going to another page."
    } else if (errorCode === "403") {
        title = "Unauthorized access"
        subtitle1 = "403 ERROR"
        subtitle2 = "You don't have permission to access this page. Try reaching out to the admin or going to another page."
    } else if (
        errorCode === "This user has not been registered yet."
        || errorDescription === "This user has not been registered yet."
    ) {
        title = "There's a problem accessing this account."
        subtitle1 = "USER LOGIN ERROR"
        subtitle2 = "There's a problem that has prevented access to this account. Try contacting the account admin or CJ Account Team to request access to this account."
    } else {
        title = "There's a problem with the system."
        subtitle1 = "SIGNIN ERROR"
        subtitle2 = "There could be a misconfiguration in the system or a service outage. We track these errors automatically, but if the problem persists feel free to contact us. Please try again."
    }

    return <BlankSlate
        title={title}
        headerGraphic={<img
            src={`${errorCode === "503" || errorCode === "404" ? loadingSvg : infoSvg}`}
            alt={'error graphic'}
            className={"headerGraphic"}
        />}>

        <SubTitle>
            <p>{subtitle1}</p>
        </SubTitle>

        <Description>
            <p>{subtitle2}</p>
        </Description>

        { errorCode === "503"
            ? <PrimaryActionButton
                label={"REFRESH PAGE" }
                handler={() => props.history.back()}
            />
            : <PrimaryActionButton
                label={"GO TO THE HOMEPAGE"}
                handler={() => props.history.push("https://www.cj.com")}
            />
        }

        <SecondaryActionButton
            label={"SUPPORT LINK"}
            handler={() => props.history.push("https://www.cj.com/support")}
        />
    </BlankSlate>
}