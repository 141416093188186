import React from 'react';
// import { Nav } from '@platform/navigation';
import ComingSoon from './Components/ComingSoon';
import { oauthConfig } from './oauth-config';
import {ErrorMessage} from "./Components/ErrorMessage/ErrorMessage";
import {Router, Route, Switch} from "react-router";
import {UnblockSuccessMessage} from "./Components/UnblockSuccessMessage/UnblockSuccessMessage";

class App extends React.Component {
  // order matters
  routes = [
    {
      path: '/program/admin/network-profile',
      render: props => <ComingSoon title="Network Profile" {...props} />
    },
    {
      path: '/program/admin/account-settings',
      render: props => <ComingSoon title="Account Settings" {...props} />
    },
    {
      path: '/program/admin/users',
      render: props => <ComingSoon title="Users" {...props} />
    },
    {
      path: '/program/admin/activity',
      render: props => <ComingSoon title="Activity" {...props} />
    },
    {
      path: '/program/admin/clients',
      render: props => <ComingSoon title="Clients" {...props} />
    },
    {
      path: '/program/admin/tag-testing',
      render: props => <ComingSoon title="Tag Testing" {...props} />
    },
    {
      path: '/program/admin/subscription',
      render: props => <ComingSoon title="Subscriptions" {...props} />
    },
    {
      path: '/program/admin/apps',
      render: props => <ComingSoon title="Apps" {...props} />
    },
    {
      path: '/admin-profile',
      render: props => <ComingSoon title="Admin Profile" {...props} />
    },
    {
      path: '/promotional-properties',
      render: props => <ComingSoon title="Promotional Properties" {...props} />
    },
    {
      path: '/settings',
      render: props => <ComingSoon title="Settings" {...props} />
    },
    {
      path: '/subscriptions',
      render: props => <ComingSoon title="Subscriptions" {...props} />
    }
  ];

  render() {
    return <Switch>
        <Route path={'/error'} render={(props) => <ErrorMessage {...props}/>} />
        <Route path={'/unblocked-account-success'} render={(props) => <UnblockSuccessMessage {...props}/>}/>
      </Switch>
  }
}

export default App;
