import React from 'react';

export default class ComingSoon extends React.Component {
  render() {
    return (
      <div>
        <h3>{this.props.title}</h3>
        {this.props.content || 'page content coming soon...'}
      </div>
    );
  }
}
